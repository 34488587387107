<template>
  <div id="version">
    <div class="bg">
      <b-img :src="require('../../assets/img/case/bac.png')"></b-img>
    </div>
    <b-container fluid
                 class="part1">
      <div class="part1_title">客户案例精选</div>
      <b-row class="">

        <b-col cols="6"
               sm="4"
               class="mb-4 "
               v-for="(item,i) in dataTable"
               @click="todetail(item.id)"
               :key="i">
          <!-- <b-card :img-src="item.surface_img"
                  img-alt="Image"
                  img-top
                  :title="item.name"
                  tag="article">
            <b-card-text>
              {{item.subtitle}}
            </b-card-text>
          </b-card> -->
          <div class="list">
            <div class="list_img">
              <div class="child">
                <img :src="item.surface_img"
                     alt="">
              </div>
            </div>
            <div class="list_info">
              <div class="title">{{item.name}}</div>
              <div class="text">{{item.subtitle}}</div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="more"
           v-show="!is_all"
           @click="more">查看更多</div>
      <div class="more"
           v-show="is_all">已加载全部</div>
    </b-container>

    <myhead :background="'transparent'"></myhead>
    <foot></foot>
    <right></right>
  </div>
</template>

<script>
import myhead from '@/components/head.vue'
import foot from '@/components/foot.vue'
import right from '@/components/right.vue'
export default {
  name: "case",
  data () {
    return {
      is_all: false,
      dataTable: [
      ],
      dataPagination: {
        currentPage: 1, //当前页码
        perPage: 15, //每页显示
        lastPage: 1, //总页码
        total: 1, //总条
      },
    }
  },
  components: {
    foot,
    right,
    myhead
  },
  mounted () {
    this.list()
  },
  methods: {
    more () {
      this.dataPagination.currentPage++;
      this.list()
    },
    todetail (id) {
      this.$router.push({ name: "CaseDetail", query: { id: id } })
    },
    list () {
      let params = {
        with_paginate: 1,
        page: this.dataPagination.currentPage,
      }
      this.$axios.get(`${this.$Tools.Conts.domain}official/successful-cases`, { params }).then(res => {
        if (res.data.code == 0) {
          // res.data.data.data.map((val) => {
          //   let dd = val.body.replace(/<[^>]+>/g, "");//截取html标签
          //   let dds = dd.replace(/&nbsp;/ig, "");//截取空格等特殊标签
          //   val["info"] = dds.substring(0, 200)
          // })

          if (this.dataPagination.currentPage == 1) {
            this.dataTable = res.data.data.data
          } else {
            res.data.data.data.map((val) => {
              this.dataTable = this.dataTable.concat(val)
            })
          }
          if (res.data.data.data.length == 0) {
            this.$toast.warn({
              message: "暂无更多数据",
              timeOut: 2000
            })
            this.is_all = true
          }

          Object.assign(this.dataPagination, {
            lastPage: res.data.data.last_page, //总页码
            perPage: res.data.data.per_page, //每页显示
            total: res.data.data.total, //总条数
          });
        } else {
          this.$toast.warn({
            message: res.data.message,
            timeOut: 2000
          })
        }

      })
    }
  }
}
</script>

<style lang="less" scoped>
#version {
  background: rgb(247, 247, 247);
  .bg {
    height: 14rem;
    background: url(/img/bac.5c0bb12d.png) no-repeat center center;
    background-size: 170%;
    img {
      width: 100%;
      display: none;
    }
  }
}
.part1 {
  padding: 3rem 0;
  .part1_title {
    font-size: 1.2rem;
    text-align: center;
    padding: 0 0 2rem;
  }
  .row {
    width: 100%;
    margin: auto;
    padding: 15px 15px 0;
    & > div {
      padding: 0;
      &:nth-child(2n) {
        padding-left: 8px;
      }
      &:nth-child(2n + 1) {
        padding-right: 8px;
      }
    }
    .card {
      cursor: pointer;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      .card-body {
        padding: 1rem;
        .card-title {
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
        .card-text {
          font-size: 0.7rem;
          color: #999999;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
    .list {
      cursor: pointer;
      background: white;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border-radius: 0.25rem;
      overflow: hidden;
      .list_img {
        // .child {
        //   height: 0;
        //   padding-bottom: 50%;
        //   position: relative;
        //   img {
        //     position: absolute;
        //     top: 0;
        //     bottom: 0;
        //     left: 0;
        //     right: 0;
        //     width: 100%;
        //     height: 100%;
        //   }
        // }
        .child {
          img {
            width: 100%;
          }
        }
      }
      .list_info {
        padding: 1rem;
        .title {
          font-size: 1rem;
          margin-bottom: 0.5rem;
        }
        .text {
          font-size: 0.8rem;
          color: #999999;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .more {
    padding: 0.5rem 0;
    color: rgb(45, 140, 240);
    font-size: 0.8rem;
    text-align: center;
    cursor: pointer;
    background: white;
    width: calc(100% - 30px);
    margin: auto;
  }
}

@media screen and (min-width: 576px) {
  #version {
    background: rgb(247, 247, 247);
    .bg {
      height: auto;
      img {
        width: 100%;
        display: block;
      }
    }
  }
  .part1 {
    padding: 3rem 0;
    .part1_title {
      font-size: 2rem;
      text-align: center;
      padding: 0 0 2rem;
    }
    .row {
      width: 75%;
      margin: auto;
      padding: 0;
      & > div {
        padding: 0 15px;
        &:nth-child(2n) {
          padding-left: 15px;
        }
        &:nth-child(2n + 1) {
          padding-right: 15px;
        }
      }
      .card {
        cursor: pointer;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        .card-body {
          padding: 1.25rem;
          .card-title {
            font-size: 1.1rem;
            margin-bottom: 0.75rem;
          }
          .card-text {
            font-size: 0.8rem;
            color: #999999;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
      }
      .list {
        cursor: pointer;
        background: white;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 0.25rem;
        overflow: hidden;
        .list_img {
          // .child {
          //   height: 0;
          //   padding-bottom: 50%;
          //   position: relative;
          //   img {
          //     position: absolute;
          //     top: 0;
          //     bottom: 0;
          //     left: 0;
          //     right: 0;
          //     width: 100%;
          //     height: 100%;
          //   }
          // }
          .child {
            img {
              width: 100%;
            }
          }
        }
        .list_info {
          padding: 1.25rem;
          .title {
            font-size: 1rem;
            margin-bottom: 0.75rem;
          }
          .text {
            font-size: 0.8rem;
            color: #999999;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .more {
      padding: 0.5rem 0;
      color: rgb(45, 140, 240);
      font-size: 0.8rem;
      text-align: center;
      cursor: pointer;
      background: white;
      width: calc(75% - 30px);
      margin: auto;
    }
  }
}
</style>